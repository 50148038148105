<template>

    <div class="container">

        <h5>Upsss</h5>
        <img src="../assets/404.svg" alt="404 error">
        <p>No encontramos la página que estás buscando</p>

        <GeneralButton msg="&nbsp;&nbsp;Volver al inicio" type="primary" idName="404_back_button" link="/"
            icon="angle-left" @click="goBack" />

    </div>

</template>


<script>
import GeneralButton from '../components/GeneralButton.vue'
import apiService from '../services/api.service'

export default {
    name: 'Error404Page',
    components: {
        GeneralButton
    },
    methods: {
        postUserAuditEvent() {
            apiService.postUserAudits('load_error_404', { page: 'error_404' })
        },
        goBack() {
            apiService.postUserAudits('click_back', { page: 'error_404' })
        }
    },
    mounted() {

        this.$gtag.event(`404_page_loaded`, {
            action: `404_page_loaded`,
            category: 'pageloaded',
            label: `404_page_loaded`,
        })
        this.postUserAuditEvent()
    }
}
</script>


<style scoped>
.container {
    height: 100vh;
    overflow: hidden;
    background-image: url('../assets/map.jpg');
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

h5 {
    font-size: 20px;
}

img {
    width: 80%;
    height: auto;
    max-width: 480px;
}

p {
    color: #339189;
    width: 70%;
}

button {
    max-width: fit-content;
}


@media (min-width: 992px) {
    h5 {
        font-size: 30px;
    }

    img {
        min-width: 480px;
        width: 40%;
    }

    p {
        font-size: 20px;
    }
}
</style>