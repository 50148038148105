import axios from 'axios'


class ApiService {

    constructor() {
        this.api = axios.create({
            baseURL: 'https://api-b2c.pickgeo.com/v1'
        })
    }

    postCheckoutPayment(documentData) {
        return this.api.post('/checkout', documentData)
    }

    getIsochronusPolygon(profile, lat, lon, minutes) {
        return this.api.get(`/isochrone?profile=${profile}&lat=${lat}&lon=${lon}&contour_minutes=${minutes}`)
    }

    getSnapshot(polygon, lat, lon, height, width) {
        const baseUrl = new URL('https://api-b2c.pickgeo.com/v1/snapshot')

        baseUrl.searchParams.set('polygon', polygon)
        baseUrl.searchParams.set('lat', lat)
        baseUrl.searchParams.set('lon', lon)
        baseUrl.searchParams.set('height', width)
        baseUrl.searchParams.set('width',  Math.round(height / 4 * 2))

        return baseUrl.toString()
    }

    getReport(report_filename) {
        return this.api.get(`/report?report_filename=${report_filename}`)
    }

    postUserAudits(event_name, event_data) {
        console.log(event_name, event_data)
        const user_id = localStorage.getItem('userId')
        return this.api.post(`/user-audits?user_id=${user_id}&event_name=${event_name}`, event_data)
    }
}

const apiService = new ApiService()


export default apiService