<template>

    <div class="error-page">

        <div class="header">
            <h2>Hemos tenido un problema</h2>
            <p>El informe no se ha generado correctamente. Le recomendamos que se ponga en contacto con
                nuestro equipo para que podamos resolverlo lo antes posible en
                <a href="mailto:contact@pickgeo.com">contact@pickgeo.com</a>. Gracias por su
                paciencia y comprensión.
            </p>
        </div>

        <div class="text-info">
            <p>Como compensación le dejamos este código de descuento para su próxima compra:</p>
            <PromoCode @click="promoCode" />
        </div>


        <div class="button-container">
            <GeneralButton class="new-btn" idName="error_new_area_button" msg="Nuevo informe" link="/area-selection"
                type="primary" @click="newReport" />
        </div>

    </div>

</template>


<script>
import GeneralButton from '@/components/GeneralButton.vue'
import PromoCode from '../components/PromoCode.vue'
import apiService from '../services/api.service'

export default {
    name: 'ErrorPage',
    components: {
        GeneralButton,
        PromoCode
    },
    methods: {
        postUserAuditEvent() {
            apiService.postUserAudits('loaded_error_pdf', { page: 'error_pdf' })
        },
        promoCode() {
            apiService.postUserAudits('click_promo_code', { page: 'error_pdf' })
        },
        newReport() {
            apiService.postUserAudits('click_new_report', { page: 'error_pdf' })
        }
    },
    mounted() {

        this.$gtag.event(`error_page_loaded`, {
            action: `error_page_loaded`,
            category: 'pageloaded',
            label: `error_page_loaded`,
        })
        this.postUserAuditEvent()

    }
}
</script>


<style scoped>
.error-page {
    padding: 8% 5%;
    height: fit-content;
    overflow: hidden;
}

.header {
    color: white;
    background-color: #00756B;
    border-radius: 10px;
    padding: 20px;
}

.header h2 {
    margin-bottom: 10px;
}

.header p {
    margin-top: 0;
}

.text-info p {
    margin: 5% 2%;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

button {
    margin: 10px;
}

.new-btn {
    width: fit-content;
    padding: 10px 30px;
}

a {
    display: inline;
    color: white;
    text-decoration: underline;
    margin: 0;
}

@media (min-width: 992px) {
    .error-page {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .header {
        grid-column: 1;
        grid-row: 1/3;
    }

    .text-info {
        grid-column: 2;
        grid-row: 1;
    }

    .button-container {
        grid-column: 2;
        grid-row: 2;
        display: grid;
        justify-items: center;
    }

    .new-btn {
        grid-row: 2;
        grid-column: 1/3;
        padding: 10px 40px;
    }
}
</style>