<template>

    <div id="success_page">

        <div class="header">
            <h2>¡Enhorabuena!</h2>
            <h5>Ya casi tiene su informe</h5>
        </div>

        <img src="persona-papeles.jpg" alt="happy person">

        <span class="text-area">
            <p>Dentro de muy poco recibirá un archivo PDF en la bandeja de entrada de su correo electrónico</p>
            <p>A continuación le dejamos un código de <strong>50% de descuento</strong> para su próxima compra:</p>
            <PromoCode @click="promoCode" />
        </span>

        <p v-show="this.showErrorMsg" class="error-msg">
            <strong> Estamos experimentando un retraso inusual en la generación del informe. </strong> <br> Pedimos
            disculpas por cualquier inconveniente que esto pueda causar. Le recomendamos que se ponga en contacto con
            nuestro equipo para que podamos resolverlo lo antes posible. Gracias por su paciencia y comprensión.
        </p>

        <n-spin :show="this.isCharging && !this.showErrorMsg">
            <a :href="this.reportFile" v-show="!this.showErrorMsg">
                <NButton class="download-btn" type="primary" @click="downloadDocument">Descargar informe &nbsp;&nbsp;
                    <font-awesome-icon icon="arrow-down" />
                </NButton>
            </a>
        </n-spin>

        <div class="button-container">
            <NButton class="invoice-btn" type="primary" :bordered="false" @click="downloadInvoice">
                Descargar factura
            </NButton>

            <GeneralButton class="new-btn" idName="success_new_area_button" msg="Generar un nuevo informe"
                link="/area-selection" type="fourth" @click="newReport" />
        </div>

    </div>

</template>


<script>
import apiService from '../services/api.service'
import { NButton, NSpin } from 'naive-ui'
import PromoCode from '../components/PromoCode.vue'
import GeneralButton from '../components/GeneralButton.vue'

export default {
    name: 'SuccessPage',
    components: {
        NButton,
        NSpin,
        PromoCode,
        GeneralButton
    },
    data() {
        return {
            isCharging: true,
            reportFile: null,
            interval: null,
            showErrorMsg: false
        }
    },
    methods: {
        downloadInvoice() {

            const invoiceUrl = this.$route.query.invoice_url
            window.open(invoiceUrl, '_blank')

            this.$gtag.event(`success_invoice_button_click`, {
                action: `success_invoice_button_click`,
                category: 'buttonclick',
                label: `success_invoice_button_click`,
            })

            apiService.postUserAudits('click_invoice', { page: 'success_pdf' })

        },
        downloadDocument() {

            this.$gtag.event(`document_download_button_click`, {
                action: `document_download_button_click`,
                category: 'buttonclick',
                label: `document_download_button_click`,
            })

            apiService.postUserAudits('click_download', { page: 'success_pdf' })

        },
        getReport() {

            apiService
                .getReport(this.$route.query.report_filename)
                .then(({ data }) => {
                    this.reportFile = data.url
                })
                .catch(err => console.log(err))

        },
        postUserAuditEvent() {
            apiService.postUserAudits('loaded_success_pdf', { page: 'success_pdf' })
        },
        promoCode() {
            apiService.postUserAudits('click_promo_code', { page: 'success_pdf' })
        },
        newReport() {
            apiService.postUserAudits('click_new_report', { page: 'success_pdf' })
        }
    },
    watch: {
        reportFile() {
            if (this.reportFile) {
                clearInterval(this.interval)
                this.isCharging = false
            }
        },
        showErrorMsg() {
            if (this.showErrorMsg) {
                apiService.postUserAudits('failed_document_generation', { page: 'success_pdf' })
            }
        }
    },
    mounted() {

        this.interval = setInterval(this.getReport, 5000)
        setTimeout(() => {

            if (!this.reportFile) {
                clearInterval(this.interval)
                this.showErrorMsg = true

                this.$gtag.event(`document_generation_failed`, {
                    action: `document_generation_failed`,
                    category: 'failed_document',
                    label: `document_generation_failed`,
                })
            }

        }, 150000)

        this.$gtag.event(`success_page_loaded`, {
            action: `success_page_loaded`,
            category: 'pageloaded',
            label: `success_page_loaded`,
        })
        // this.$gtag.event('conversion', {
        //     'send_to': 'AW-600211800/xKmRCJahu-gYENiCmp4C'
        // })

        this.postUserAuditEvent()

    }
}
</script>


<style scoped>
#success_page {
    height: fit-content;
    overflow: hidden;
    padding: 20px 0;
}

h2 {
    font-size: 2rem;
    margin-bottom: 0;

}

h5 {
    font-weight: 100;
    font-size: 15px;
    margin-top: 0;
}

img {
    width: 90%;
    transform: scaleX(-1);
}

p {
    margin: 20px auto;
    width: 90%;
    font-size: 14px;
}

.n-spin {
    height: max-content;
}

.download-btn {
    border-radius: 10px;
    margin: 20px auto;
    width: 50%;
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 90%;
    margin: 0 auto;
}

.invoice-btn {
    border-radius: 10px;
    background-color: white;
    color: #00756B;
}

.invoice-btn:hover {
    background-color: #E6F1F0;
}

.new-btn {
    width: fit-content;
    margin: 0;
}

.error-msg {
    background-color: #FFDED9;
    color: #A40007;
    border: 1px solid #A40007;
    border-radius: 10px;
    padding: 10px;
}


@media (min-width: 992px) {
    #success_page {
        margin: 5% 10%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
    }

    .header {
        grid-column: 1;
        grid-row: 1;
    }

    img {
        grid-column: 2;
        grid-row: 1/5;
        height: 100%;
        object-fit: cover;
    }

    .text-area {
        grid-column: 1;
        grid-row: 2;
    }

    .download-btn {
        grid-column: 1;
        grid-row: 3;
    }

    .button-container {
        grid-column: 1;
        grid-row: 4;
    }
}
</style>