<template>

    <div>

        <vue-google-autocomplete id="search-bar" ref="autocomplete" classname="form-control"
            placeholder="Introduce una dirección" @placechanged="getAddressData" :country="[ 'es' ]">
        </vue-google-autocomplete>

        <i class="fa-solid fa-magnifying-glass"></i>

    </div>

</template>


<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { mapState, mapActions } from 'vuex'
import apiService from '../services/api.service'

export default {
    name: 'SearchBar',
    computed: {
        ...mapState([ 'coordinates' ])
    },
    components: {
        VueGoogleAutocomplete
    },
    methods: {
        ...mapActions([ 'setCoordinates' ]),
        getAddressData(addressData) {

            this.$gtag.event(`search_bar_click`, {
                action: `search_bar_click`,
                category: 'buttonclick',
                label: `search_bar_click`,
            })

            const selectedLngLat = {
                lng: addressData.longitude,
                lat: addressData.latitude
            }
            this.setCoordinates(selectedLngLat)

            apiService.postUserAudits('search_bar', { page: 'area_selection' })

        }
    },
}
</script>


<style scoped>
div {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 0 20px;
    position: relative;
    z-index: 1;
}

#search-bar {
    display: flex;
    margin: 23px auto;
    width: 100%;
    padding: 10px;
    border: 1px solid #00756B;
    border-radius: 10px;
    color: #00756B;
    position: relative;
    z-index: 1;
}

#autocomplete {
    z-index: 1;
    color: #00756B;
}

i {
    position: relative;
    z-index: 1;
    right: 30px;
    color: #00756B;
    font-size: 20px;
}

@media (min-width: 992px) {
    div {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px;
        position: relative;
        z-index: 1;
    }

    #search-bar {
        width: 70%;
        padding: 10px;
        border: 1px solid #00756B;
        border-radius: 10px;
        color: #00756B;
        position: relative;
        z-index: 1;
        margin: 0;
        top: 10px;
        left: 5%;
    }

    i {
        top: 10px;
        right: 0;
    }
}
</style>